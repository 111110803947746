import type { StyleRule } from '@archipro-design/aria';

const TRANSITION_SPEED = 500; // 150ms for 250px

export const CollapsibleContainer: StyleRule<{
    initialHeight: number;
}> = ({ initialHeight }) => ({
    maxHeight: `${initialHeight + 50}px`, // Add 50px for login form error messages
    transition: `max-height ${TRANSITION_SPEED}ms linear`,
    overflow: 'hidden',
});
