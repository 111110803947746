import type { StyleRule } from '@archipro-design/aria';
import { labelClassName, pxArrayToRem, pxToRem } from '@archipro-design/aria';
import { buttonClassName } from '@archipro-design/aria';

export const FormLink: StyleRule = ({ theme }) => ({
    textAlign: 'center',
    color: theme.siteVariables.colors.charcoal[250],
    fontSize: pxToRem(12),
    lineHeight: pxToRem(15),
    [`& .${buttonClassName}`]: {
        [`& .${labelClassName}`]: {
            fontSize: pxToRem(12),
            lineHeight: pxToRem(15),
            padding: pxArrayToRem([0, 3]),
        },
    },
});
