import type { Tracker } from '@archipro-website/tracker';
import { useFetcher } from '@remix-run/react';
import { useLatest, useSessionStorageState } from 'ahooks';
import { useCallback, useEffect } from 'react';
import { useToaster } from '~/modules/root/context/ToasterContext';
import { assertSuccessResponse } from '~/modules/root/graphql/responses';
import { trackCreatedEnquiry } from '~/modules/tracking/util/trackCreatedEnquiry';
import { trackSubmitCreateEnquiryRequest } from '~/modules/tracking/util/trackSubmitCreateEnquiryRequest';

import { isUserLoggedIn, useUser } from '~/modules/user';
import type { SubmitActionResponse } from '~/routes/remix-api.enquiry.submit';
import type { CreateEnquirySource } from '..';
import { formatEnquiryPayload } from '../util/format-enquiry-payload';
import { sessionStorage } from '~/modules/root/util/browser-storage';
import { fetcherIsDone } from '~/utils/fetcherHelper';
import type { EnquiryFormShape } from '../component/enquiry-validation/enquiryValidation';
import { trackAuthEvent } from '~/modules/user/util/track-auth-event';
interface EnquirySessionProps {
    onSuccess?: () => void;
    canHandleAutoSubmit?: boolean;
}

const ENQUIRY_FORM_SESSION_KEY = 'enquiry-form-session';
const ENQUIRY_FORM_AUTOSUBMIT_KEY = 'enquiry-form-auto-submit';
const ENQUIRY_SOURCE_KEY = 'enquiry-form-source';
const PENDING_GUEST_ENQUIRY_KEY = 'pending-guest-enquiry-key';

export const useEnquirySession = (
    props: EnquirySessionProps = { canHandleAutoSubmit: false },
    enquirySource?: CreateEnquirySource,
    tracker?: Tracker
) => {
    const { canHandleAutoSubmit } = props;

    const user = useUser();

    const { toast } = useToaster();

    const fetcher = useFetcher<SubmitActionResponse>();
    const [enquirySession, setEnquirySession] = useSessionStorageState<
        Partial<EnquiryFormShape> | undefined
    >(ENQUIRY_FORM_SESSION_KEY);
    const [pendingGuestEnquiry, setPendingGuestEnquiry] =
        useSessionStorageState<boolean>(PENDING_GUEST_ENQUIRY_KEY);
    const [autoSubmit, setAutoSubmit] = useSessionStorageState<boolean>(
        ENQUIRY_FORM_AUTOSUBMIT_KEY
    );

    const [source, setSource] =
        useSessionStorageState<CreateEnquirySource>(ENQUIRY_SOURCE_KEY);

    const stableOnSuccess = useLatest(props?.onSuccess);

    useEffect(() => {
        if (enquirySource) {
            setSource(enquirySource);
        }
    }, [enquirySource, setSource]);

    // Need to migrate this to use cookies and remix flash session
    useEffect(() => {
        if (canHandleAutoSubmit) {
            //show toast when only submitting enquiry
            if (!pendingGuestEnquiry && autoSubmit) {
                setPendingGuestEnquiry(true);
            }
        }
    }, [
        autoSubmit,
        canHandleAutoSubmit,
        pendingGuestEnquiry,
        setPendingGuestEnquiry,
    ]);

    const autoSubmitStoredEnquiry = useCallback(
        (forceSubmit?: boolean) => {
            if (typeof window === 'undefined') {
                return;
            }

            // Hook usages can be out of sync if component does not rerender/remount
            const refreshEnquiry = sessionStorage?.getItem(
                ENQUIRY_FORM_SESSION_KEY
            );
            if (!refreshEnquiry) return;
            const enquiryForm = JSON.parse(refreshEnquiry);
            if (
                (autoSubmit || forceSubmit) &&
                enquiryForm &&
                user.__typename === 'Me'
            ) {
                setAutoSubmit(false); // Prevent multiple submissions
                const payload = formatEnquiryPayload(enquiryForm);
                // Only submit enquiries for the account we are logged into
                payload.email = user.Email;
                payload.name = `${user.FirstName} ${user.LastName}`;

                if (tracker) {
                    trackSubmitCreateEnquiryRequest(tracker, {
                        source: source ?? 'InPage',
                        isAutoSubmit: true,
                    });
                }

                fetcher.submit(payload, {
                    method: 'post',
                    action: '/remix-api/enquiry/submit',
                });

                if (tracker) {
                    if (source !== 'EnquiryModal') {
                        tracker.log('OnPageEnquirySend', {
                            url: new URL(window.location.href),
                            targetTracker: 'archiproTracker',
                        });
                    }

                    trackCreatedEnquiry(tracker, {
                        source: source ?? 'InPage',
                        isMemberGenerated: user.TempPassword,
                    });
                }
            }
        },
        [autoSubmit, user, setAutoSubmit, tracker, fetcher, source]
    );

    useEffect(() => {
        if (canHandleAutoSubmit) {
            // autoSubmit can be stale if session storage is updated form another hook
            const refreshAutoSubmit =
                window.sessionStorage.getItem(ENQUIRY_FORM_AUTOSUBMIT_KEY) ===
                'true';
            if (refreshAutoSubmit && isUserLoggedIn(user)) {
                autoSubmitStoredEnquiry(refreshAutoSubmit);
            }
        }
    }, [autoSubmitStoredEnquiry, canHandleAutoSubmit, user]);

    useEffect(() => {
        if (fetcherIsDone(fetcher) && fetcher.data) {
            const success = assertSuccessResponse(fetcher.data);
            if (success) {
                stableOnSuccess.current?.();
                toast('Enquiry Sent');
                if (
                    tracker &&
                    'data' in fetcher.data &&
                    fetcher.data.data &&
                    'GeneratedMember' in fetcher.data.data &&
                    fetcher.data.data.GeneratedMember
                ) {
                    trackAuthEvent({
                        tracker,
                        event: 'new_account_registered',
                        provider: 'AutoGenerated',
                        authSource: 'enquiry',
                        guestID:
                            fetcher.data.data.GeneratedMember.TrackedGuest?.ID,
                    });
                }
            }
        }
    }, [stableOnSuccess, fetcher, toast, tracker]);

    const removeEnquirySession = useCallback(() => {
        if (typeof window === 'undefined') {
            return;
        }
        window.sessionStorage.removeItem(ENQUIRY_FORM_SESSION_KEY);
    }, []);

    const handlePostEnquiryCancel = () => {
        if (typeof window === 'undefined') {
            return;
        }

        const refreshEnquiryString = sessionStorage?.getItem(
            ENQUIRY_FORM_SESSION_KEY
        );
        if (!refreshEnquiryString) return;
        const refreshEnquiry = JSON.parse(refreshEnquiryString);
        refreshEnquiry.createAccount = 'true';
        setEnquirySession(refreshEnquiry);
    };

    const me = user.__typename === 'Me' ? user : undefined;
    const newEnquiryDefault = me
        ? {
              ...((me.FirstName || me.LastName) && {
                  name: `${me.FirstName} ${me.LastName}`,
              }),
              ...(me.Email && { email: me.Email }),
              ...(me.Phone && { phoneNumber: me.Phone }),
              ...(enquirySession?.suburbPostcode && {
                  suburbPostcode: enquirySession?.suburbPostcode,
              }),
          }
        : {
              ...(enquirySession?.name && {
                  name: enquirySession.name,
              }),
              ...(enquirySession?.email && {
                  email: enquirySession.email,
              }),
              ...(enquirySession?.phoneNumber && {
                  phoneNumber: enquirySession.phoneNumber,
              }),
              ...(enquirySession?.suburbPostcode && {
                  suburbPostcode: enquirySession.suburbPostcode,
              }),
              ...(enquirySession?.message && {
                  message: enquirySession.message,
              }),
          };

    const resumeEnquiryDefault = { ...enquirySession, ...newEnquiryDefault };

    return {
        enquirySession,
        newEnquiryDefault,
        resumeEnquiryDefault,
        setEnquirySession,
        autoSubmitLocalValue: autoSubmit,
        setAutoSubmit,
        autoSubmitStoredEnquiry,
        handlePostEnquiryCancel,
        removeEnquirySession,
    };
};
