/* eslint-disable no-useless-escape */
// noinspection RegExpSimplifiable,RegExpUnnecessaryNonCapturingGroup
export const NAME_REGEX = /^(?!.*([\s-])\1)[a-zA-Z\s-'’]+$/; // Negative lookahead, only allow one consecutive space or hypen

export const FULLNAME_REGEX = /^[a-zA-Z-'’.]+\s{1}[a-zA-Z-'’. ]+$/; // Force a space in the middle of the name so we can split

export const PHONE_NUMBER_REGEX =
    /(?=(^([a-zA-Z+ ()-]*[0-9][a-zA-Z+ ()-]*){4,24})$)(?=^[-+]?[(]?[a-zA-Z0-9]+[)]?([ -]?[a-zA-Z0-9]+)*([ -]?[(]([\s-]?[a-zA-Z0-9]+)*[)])?$)(?=(?:(?=^.*[a-zA-Z]+.*$)((^.*[ -]+.*[0-9]+.*$)|(^.*[0-9]+.*[- ]+.*$))|(?!(?=^.*[a-zA-Z]+.*$)(^.*$))))(.*)/;

export const EMAIL_REGEX =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
