import type { EnquiryFormShape } from '../component/enquiry-validation/enquiryValidation';
interface EnquiryFormOverrides extends Partial<EnquiryFormShape> {
    createAccount: boolean;
}

export const formatEnquiryPayload = (
    data: EnquiryFormShape,
    overrides: EnquiryFormOverrides = { createAccount: false }
) => {
    const {
        branch = undefined,
        suburbPostcode,
        shareDetails,
        siteTreeId,
        ...rest
    } = data;

    const locationResponse = suburbPostcode?.value ?? {};
    const branchId = branch?.value?.branchId?.toString() ?? '';
    const suburbString = locationResponse?.region ?? '';

    return {
        ...rest,
        branchId,
        siteTreeId: siteTreeId.toString(),
        suburbPostcode: suburbString,
        locationResponse: JSON.stringify(locationResponse),
        shareDetails: shareDetails.toString(),
        createAccount: overrides.createAccount.toString(),
    };
};
