import type {
    LabelProps,
    ShorthandValue,
    StyleRule,
} from '@archipro-design/aria';
import { useStyles } from '@archipro-design/aria';
import { ToastMessage } from '@archipro-design/aria';

import { useMemoizedFn } from 'ahooks';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

export interface ToastOptions {
    type?: 'error' | 'success' | 'notification';
    autoHideDelay?: number;
    clickHereLink?: ShorthandValue<LabelProps>;
    styleRule?: StyleRule;
    onAfterShow?: () => void;
}

interface ToasterContextType {
    toast: (message: string, options?: ToastOptions) => void;
    closeToast: () => void;
}

const ToasterContext = React.createContext<ToasterContextType | undefined>(
    undefined
);

const WAIT_ANIMATION_SECONDS = 0.5;
const DEFAULT_AUTO_HIDE_SECONDS = 6;

export const useToaster = (
    initialOptions: Omit<ToastOptions, 'clickHereLink' | 'type'> = {}
) => {
    const context = React.useContext(ToasterContext);

    if (!context) {
        throw new Error('Cannot be used outside ToasterContext');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const memoizedOptions = useMemo(() => initialOptions, []);
    const memoizedToast = useMemoizedFn(
        (message: string, options?: ToastOptions) =>
            context.toast(message, { ...memoizedOptions, ...options })
    );

    const enquiryToastClickHere = useMemoizedFn((generatedMember = false) => ({
        as: 'a', // Todo: change to Link after we have the Remix version
        href: generatedMember
            ? `/member/settings/security?changePassword=1`
            : '/member/inbox',
        children: 'Click here',
        onClick: context.closeToast,
    }));

    return {
        toast: memoizedToast,
        closeToast: context.closeToast,
        enquiryToastClickHere,
    };
};

export const ToasterProvider: React.FC<React.PropsWithChildren> = ({
    children,
}) => {
    const [message, setMessage] = useState<string>();
    const [toastVisible, setToastVisible] = useState<boolean>();
    const [{ styleRule, ...rest }, setOptions] = useState<ToastOptions>({});
    const { css } = useStyles();

    const toast = useCallback(
        (newMessage: string, options: ToastOptions = {}) => {
            const isPreviousToastVisible = !!message;
            isPreviousToastVisible && setToastVisible(false);
            const newAutoHideDelay =
                (options.autoHideDelay || DEFAULT_AUTO_HIDE_SECONDS) +
                (isPreviousToastVisible ? DEFAULT_AUTO_HIDE_SECONDS / 2 : 0);
            setTimeout(
                () => {
                    setMessage(newMessage);
                    setOptions({ ...options, autoHideDelay: newAutoHideDelay });
                    setToastVisible(undefined);
                },
                isPreviousToastVisible ? WAIT_ANIMATION_SECONDS * 1000 : 0
            );
        },
        [message]
    );

    const closeToast = useMemoizedFn(() => {
        setMessage(undefined);
        setOptions({});
    });

    const toasterContext = useMemo(
        () => ({ toast, closeToast }),
        [toast, closeToast]
    );
    // trigger onAfter show when message appear
    useEffect(() => {
        if (message && rest.onAfterShow) {
            rest.onAfterShow();
        }
    }, [message, rest, rest.onAfterShow]);

    return (
        <ToasterContext.Provider value={toasterContext}>
            {children}
            {message && (
                <ToastMessage
                    message={message}
                    onClose={() => setMessage(undefined)}
                    className={styleRule ? css(styleRule) : undefined}
                    {...rest}
                    visibleValue={toastVisible}
                />
            )}
        </ToasterContext.Provider>
    );
};
