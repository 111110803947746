import { hasFeature } from '~/modules/root/util/has-feature';
import { useUser } from './use-user';

const isProductionStage = (stage: string | undefined): boolean => {
    return !!stage && ['Production', 'Staging'].includes(stage);
};

export const useBypassForcedSocial = (stage: string) => {
    const user = useUser();

    const isProduction = isProductionStage(stage);

    const bypassForcedSocial =
        hasFeature(user.FeatureFlags, 'bypass_forced_social_signin') &&
        !isProduction;

    return {
        bypassForcedSocial,
    };
};
