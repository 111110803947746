import { SOCIAL_PROVIDERS } from '../component/social-login/type';

export interface GrantedPermissions {
    editProfile: boolean;
    editAdvertAdmin: boolean;
}

export const AUTH_SOURCES = [
    'unknown',
    'enquiry',
    'topNav',
    'downloadsgate',
    'loginPage',
    'homeModule',
    'designBoard',
    'specialtyPricingBanner',
    'tradeOnlyBanner',
    'adPlacementModule',
    'headerModule',
    'headerMenu',
    'aboutCallToAction',
    'videoModule',
    'articleGate',
    'designBoardGate',
    'siteGate',
    'footer',
] as const;

export type AuthSource = (typeof AUTH_SOURCES)[number];

export const AUTH_PROVIDERS = [
    ...SOCIAL_PROVIDERS,
    'EmailPassword',
    'AutoGenerated',
] as const;

export type AuthProvider = (typeof AUTH_PROVIDERS)[number];

export const AUTH_TYPES = ['sign-up', 'sign-in'] as const;

export type AuthType = (typeof AUTH_TYPES)[number];
