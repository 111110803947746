import type { Tracker, TrackingEvent } from '@archipro-website/tracker';
import type { AuthProvider, AuthSource } from '../type';

interface TrackAuthEventOpt {
    tracker: Tracker;
    event: Extract<
        TrackingEvent,
        | 'new_account_registered'
        | 'user_login'
        | 'AuthSocialButtonClick'
        | 'AuthEmailInput'
        | 'AuthAccountRecognized'
        | 'AuthSignUpSubmit'
        | 'AuthSignInSubmit'
    >;
    authSource: AuthSource;
    provider: AuthProvider;
    guestID?: number;
    data?: Record<string, unknown>;
}

export const trackAuthEvent = async ({
    tracker,
    event,
    provider,
    authSource,
    guestID = 0,
    data,
}: TrackAuthEventOpt) => {
    await tracker.log(event, {
        data: { ExtraData: provider, Source: authSource },
        ga4Data: {
            provider,
            source: authSource,
            guest_id: guestID,
            ...(data ?? {}),
        },
        url: new URL(window.location.href),
        targetTracker: ['archiproTracker', 'ga4Tracker'],
    });
};
