import { parseURL } from '@archipro-website/top-navigation/src/lib/utils/url-helpers';
import {
    ENQUIRE_AUTH_SOURCE_VALUE,
    ENQUIRE_MODAL_SHOWUP_KEY,
    ENQUIRE_MODAL_TYPO_KEY,
} from '~/modules/enquiry/constants';
import {
    AUTH_PROVIDER_PARAM_KEY,
    AUTH_TYPE_PARAM_KEY,
    AUTH_SOURCE_PARAM_KEY,
} from '~/modules/root/hook/use-auth-event';

export const FIREFOX_REDIRECT_WAIT_TIME = 1000;
export const CHROME_REDIRECT_WAIT_TIME = 500;
export const TOAST_DISPLAY_WAIT_TIME = 3000;

export const handleAuthRedirect = async ({
    newURL,
    authSource,
    isFirefox = false,
}: {
    newURL: string;
    authSource?: string;
    isFirefox?: boolean;
}) => {
    const { basePath: originBasePath } = parseURL(window.location.href);
    const { basePath: currentBasePath } = parseURL(newURL);
    const originalUrl = new URL(window.location.href);
    const pendingUrl = new URL(newURL, window.location.origin);
    let finalURL = newURL;

    if (authSource === ENQUIRE_AUTH_SOURCE_VALUE) {
        // remove the enquire/enquiry param to avoid enquiry form showing up again
        if (
            newURL.includes(ENQUIRE_MODAL_SHOWUP_KEY) ||
            newURL.includes(ENQUIRE_MODAL_TYPO_KEY)
        ) {
            pendingUrl.searchParams.delete(ENQUIRE_MODAL_SHOWUP_KEY);
            pendingUrl.searchParams.delete(ENQUIRE_MODAL_TYPO_KEY);
            finalURL =
                pendingUrl.pathname + pendingUrl.search + pendingUrl.hash;
        }
        // wait for the enquiry toast to display
        await new Promise((resolve) =>
            setTimeout(resolve, TOAST_DISPLAY_WAIT_TIME)
        );
    }

    // if pendingUrl contains provider param, it's a social login redirect
    if (pendingUrl.searchParams.has(AUTH_PROVIDER_PARAM_KEY)) {
        pendingUrl.searchParams.delete(AUTH_PROVIDER_PARAM_KEY);
        pendingUrl.searchParams.delete(AUTH_TYPE_PARAM_KEY);
        pendingUrl.searchParams.delete(AUTH_SOURCE_PARAM_KEY);
        finalURL = pendingUrl.pathname + pendingUrl.search + pendingUrl.hash;
    }

    const canReload =
        (originBasePath === currentBasePath ||
            originalUrl.pathname === currentBasePath) &&
        originalUrl.searchParams.toString() ===
            pendingUrl.searchParams.toString();

    // workaround for fixing firefox redirect aborting issue
    setTimeout(
        () => {
            if (canReload) {
                // have to reload() to reset GrowthBook and Google tags
                // todo: need to find a better solution to get rid of this
                window.location.reload();
            } else {
                window.location.href = finalURL;
            }
        },
        // when redirecting on firefox, wait longer to avoid redirect aborting issue
        isFirefox ? FIREFOX_REDIRECT_WAIT_TIME : CHROME_REDIRECT_WAIT_TIME
    );
};
