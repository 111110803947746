/* eslint-disable max-lines */
import type {
    ButtonProps,
    LoginModalProps,
    ShorthandValue,
    ComponentEventHandler,
    DialogProps,
} from '@archipro-design/aria';
import {
    useAppDisplayModeContext,
    useDeviceBrowserContext,
} from '@archipro-design/aria';
import { Flex } from '@archipro-design/aria';
import { Subheader } from '@archipro-design/aria';
import { useStyles } from '@archipro-design/aria';
import { useTheme } from '@archipro-design/aria';
import { Caption } from '@archipro-design/aria';
import { LoginModal } from '@archipro-design/aria';
import { Divider } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';
import { ArrowLeftLine } from '@archipro-design/icons';
import { useLatest } from 'ahooks';
import { useEffect, useMemo, useState } from 'react';
import { useEnquirySession } from '~/modules/enquiry/hook/use-enquiry-session';
import { usePublicUser } from '../../hook/use-public-user';
import ForgotPasswordForm from '../forgot-password/ForgotPasswordForm';
import type { LoginSignUpFormProps } from '../login-signup-form/LoginSignUpForm';
import { useBoolean } from 'ahooks';
import LoginSignUpForm from '../login-signup-form/LoginSignUpForm';

import SocialLogin from '../social-login/SocialLogin';
import * as S from './LoginSignUpModal.style';
import type { LoginFlow } from '../../hook/use-login-flow';
import { PolicyLink } from '../policy-link/PolicyLink';
import { useBypassForcedSocial } from '../../hook/use-bypass-forced-social';
import { useRootData } from '~/modules/root';
import cx from 'classnames';
import { useTracker } from '@archipro-website/tracker';
import { useSocialLogins } from '~/modules/user/component/social-login/hook/use-social-logins';

interface LoginSignUpModalProps {
    login: LoginModalProps &
        Pick<
            LoginSignUpFormProps,
            | 'flow'
            | 'defaultPublicUser'
            | 'redirect'
            | 'allowExternalUrls'
            | 'message'
            | 'skipPostSignUp'
            | 'authSource'
        > & {
            subtitle?: string;
            disableCloseButton?: boolean;
            onSuccess?: ComponentEventHandler<DialogProps>;
            onAuthSuccess: () => void;
        };
    forgotPassword: LoginModalProps;
}

/**
 * You can use useAuth to show or hide the modal, don't create directly
 * @param props
 * @returns
 */
const LoginSignUpModal = (props: LoginSignUpModalProps) => {
    const theme = useTheme();
    const { mobile: isMobile } = useAppDisplayModeContext();
    const { login, forgotPassword } = props;
    const {
        flow,
        defaultPublicUser,
        authSource,
        allowExternalUrls,
        skipPostSignUp,
        redirect,
        onAuthSuccess,
        onSuccess: _,
        ...loginModalProps
    } = login;
    const { css } = useStyles({ flow });
    const [form, setForm] = useState<'forgot-password' | 'login'>('login');
    const { enquirySession } = useEnquirySession();
    const { publicUser, handleUserExists } = usePublicUser(
        login.authSource,
        defaultPublicUser
    );

    const { env } = useRootData();

    const tracker = useTracker();

    const { bypassForcedSocial } = useBypassForcedSocial(env.stage);
    const [useEmail, setUseEmail] = useState(false);

    const { isInstagramOrFacebookApp } = useDeviceBrowserContext();
    const availableSocialLogins = useSocialLogins({
        loginOptions: publicUser?.socialLoginOptions,
        authSource,
        redirect,
        skipPostSignUp: !!skipPostSignUp,
        isInstagramOrFacebookApp,
    });

    const hideEmailAndButton =
        publicUser?.socialLoginOptions.length === 1 &&
        !useEmail &&
        !bypassForcedSocial;

    const getFlow = (): LoginFlow => {
        const isPostEnquiry = flow?.includes('post-enquiry');
        if (publicUser?.type === 'new') {
            return isPostEnquiry ? 'post-enquiry-sign-up' : 'sign-up';
        }
        if (publicUser?.type === 'existing') {
            return isPostEnquiry ? 'post-enquiry-login' : 'login';
        } else return null;
    };

    useEffect(() => {
        if (!loginModalProps.open) return;
        tracker.log('AuthModalImpression', {
            url: new URL(window.location.href),
            data: { Source: authSource },
            ga4Data: { source: authSource },
            targetTracker: ['archiproTracker', 'ga4Tracker'],
        });
        if (availableSocialLogins.length === 0) {
            setUseEmail(true);
        }
    }, [
        authSource,
        loginModalProps.open,
        tracker,
        availableSocialLogins.length,
    ]);

    const { current: loginFlow } = useLatest(getFlow());

    const renderHeader = () => {
        if (loginFlow === 'login' || loginFlow === 'post-enquiry-login')
            return 'Log in';
        if (loginFlow === 'sign-up' || loginFlow === 'post-enquiry-sign-up')
            return 'Sign up';
        return 'Log in or sign up';
    };

    const renderWelcomeMessage = useMemo(() => {
        if (defaultPublicUser?.type === 'new' && enquirySession?.name) {
            return `Welcome, ${enquirySession.name}`;
        }
        if (publicUser?.type === 'existing' && publicUser?.firstName) {
            return `Welcome back, ${publicUser.firstName}`;
        } else {
            return 'Welcome to ArchiPro';
        }
    }, [defaultPublicUser?.type, enquirySession, publicUser]);

    const renderFooterContent = () => {
        if (loginFlow === 'login' || loginFlow === 'post-enquiry-login') {
            return (
                <Caption
                    link
                    variant="05-alt"
                    onClick={() => {
                        setForm('forgot-password');
                    }}
                    variables={{
                        captionColor: theme.siteVariables.colors.charcoal[250],
                    }}
                >
                    Forgot password?
                </Caption>
            );
        }

        return (
            <div className={css(S.FormLink)}>
                <Caption variant="05-alt" inline>
                    By signing up, you have read and agree to our
                </Caption>
                <PolicyLink
                    variant="05-alt"
                    inline={true}
                    link={true}
                    target="_blank"
                    as="a"
                    design={{ paddingLeft: pxToRem(3) }}
                    style={{ textDecoration: 'underline' }}
                />
            </div>
        );
    };

    const [forgotPasswordCompleted, setForgotPasswordCompleted] = useBoolean();
    const forgotPasswordHeader = forgotPasswordCompleted
        ? 'Reset password link sent'
        : `Forgot password?`;

    const handleForgotPasswordBack = () => {
        if (forgotPasswordCompleted) {
            return setForgotPasswordCompleted.setFalse();
        } else {
            setForm('login');
        }
    };

    const hideFooter =
        (loginFlow === 'login' && !!publicUser?.socialLoginOptions?.length) ||
        (loginFlow === 'post-enquiry-login' &&
            !!publicUser?.socialLoginOptions?.length);

    return (
        <>
            {form === 'login' && (
                <LoginModal
                    className={css(S.LoginModal)}
                    header={renderHeader()}
                    {...(isMobile && {
                        overlay: { className: css(S.OverlayClass) },
                    })}
                    headerAction={
                        login.disableCloseButton
                            ? null
                            : ({
                                  onClick: (e) => {
                                      login?.onCancel?.(e);
                                      e.stopPropagation();
                                  },
                              } satisfies ShorthandValue<ButtonProps>)
                    }
                    content={
                        <Flex column className={css(S.LoginModalContent)}>
                            <Subheader variant="02">
                                {renderWelcomeMessage}
                            </Subheader>

                            <Caption
                                variant="03-alt"
                                className={css(S.LoginSubtitle)}
                                variables={{
                                    captionColor:
                                        theme.siteVariables.colors
                                            .charcoal[100],
                                    fontSize: pxToRem(14),
                                    lineHeight: pxToRem(17.5),
                                }}
                            >
                                {login.subtitle ??
                                    'Log in or sign up for free to continue'}
                            </Caption>

                            {login.message && (
                                <Caption
                                    variant="03-alt"
                                    className={cx(
                                        css(S.LoginMessage),
                                        css(S.CommonCaption)
                                    )}
                                >
                                    {login.message}
                                </Caption>
                            )}

                            {loginFlow === 'post-enquiry-sign-up' && (
                                <Caption
                                    variant="03-alt"
                                    className={css(S.CommonCaption)}
                                >
                                    Great, you’ve made your first enquiry.&nbsp;
                                    <br />
                                    Connect your social media or enter a
                                    password to complete your account sign up.
                                </Caption>
                            )}

                            <SocialLogin
                                loginOptions={publicUser?.socialLoginOptions}
                                preOptionText={
                                    hideEmailAndButton
                                        ? 'Continue with'
                                        : undefined
                                }
                                useEmail={useEmail}
                                authSource={authSource}
                                skipPostSignUp={!!login.skipPostSignUp}
                                redirect={login.redirect}
                            />

                            {hideEmailAndButton ? (
                                <div style={{ width: '100%' }}>
                                    <Caption
                                        variant="04"
                                        variables={{
                                            color: theme.siteVariables.colors
                                                .charcoal[100],
                                        }}
                                        design={{ marginTop: pxToRem(12) }}
                                        className={css(S.CommonCaption)}
                                    >
                                        Not you? &nbsp;
                                        <span
                                            onClick={() => {
                                                setUseEmail(true);
                                            }}
                                            style={{
                                                textDecoration: 'underline',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            Use another account
                                        </span>
                                    </Caption>
                                </div>
                            ) : (
                                <>
                                    {availableSocialLogins.length > 0 && (
                                        <Divider
                                            color="grey"
                                            content={'or'}
                                            size={1}
                                            design={{
                                                width: '100%',
                                                marginTop: pxToRem(14),
                                            }}
                                            variables={{
                                                dividerPrimaryColor:
                                                    theme.siteVariables.colors
                                                        .charcoal[100],
                                                dividerPadding: pxToRem(24),
                                                textFontSize: pxToRem(12),
                                            }}
                                        />
                                    )}
                                    <LoginSignUpForm
                                        onEmailBlur={handleUserExists}
                                        onResetPassword={(e) => {
                                            setForm('forgot-password');
                                            e.preventDefault(); // Prevent following <a href> to legacy password reset
                                        }}
                                        defaultPublicUser={publicUser}
                                        {...login}
                                        flow={loginFlow}
                                        allowExternalUrls={allowExternalUrls}
                                        skipPostSignUp={!!skipPostSignUp}
                                        redirect={redirect}
                                        onSuccess={onAuthSuccess}
                                    />
                                </>
                            )}

                            {!hideFooter && (
                                <div
                                    style={{
                                        textAlign: 'center',
                                        marginTop: pxToRem(14),
                                    }}
                                >
                                    {renderFooterContent()}
                                </div>
                            )}
                        </Flex>
                    }
                    footerButton={null}
                    footerContent={null}
                    {...loginModalProps}
                />
            )}
            {form === 'forgot-password' && (
                <LoginModal
                    className={css(S.ForgotPasswordModal)}
                    header={forgotPasswordHeader}
                    headerAction={{
                        icon: <ArrowLeftLine />,
                        size: 20,
                        onClick: handleForgotPasswordBack,
                    }}
                    content={
                        <ForgotPasswordForm
                            flow={
                                forgotPasswordCompleted
                                    ? 'forgot-password-completed'
                                    : 'forgot-password'
                            }
                            onBack={() => {
                                setForm('login');
                                setForgotPasswordCompleted.setFalse();
                            }}
                            onSuccess={() => {
                                setForgotPasswordCompleted.setTrue();
                            }}
                        />
                    }
                    footerButton={null}
                    footerContent={null}
                    {...forgotPassword}
                />
            )}
        </>
    );
};

export default LoginSignUpModal;
