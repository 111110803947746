/* eslint-disable max-lines */
import {
    Button,
    Checkbox,
    pxToRem,
    TextInput,
    useAppDisplayModeContext,
    useStyles,
} from '@archipro-design/aria';

import type { z } from 'zod';

import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { EyeLine, EyeOffLine } from '@archipro-design/icons';
import { useEnquirySession } from '~/modules/enquiry/hook/use-enquiry-session';
import { useFetcher, useSearchParams } from '@remix-run/react';
import type { LoginAction } from '~/routes/remix-api.user.login';
import * as S from './LoginSignUpForm.style';

import { zodResolver } from '@hookform/resolvers/zod';
import {
    assertErrorResponse,
    assertSuccessResponse,
} from '@modules/root/graphql/responses';
import type { PublicUser } from '~/routes/remix-api.user.public-user';
import type { LoginFlow, signUpValidator } from '../../hook/use-login-flow';
import { useLoginFlow } from '../../hook/use-login-flow';
import type { SignUpAction } from '~/routes/remix-api.user.sign-up';
import { useTracker } from '@archipro-website/tracker';
import { useUser } from '../../hook';
import ExpandableContainer from '../expandable-component/ExpandableContainer';
import type { AuthSource } from '../../type';
import { useAuth } from '~/modules/root/context';
import { trackAuthEvent } from '../../util/track-auth-event';

type FormShape = z.infer<typeof signUpValidator>;

export interface LoginSignUpFormProps {
    flow: LoginFlow;
    onEmailBlur?: (email: string) => void;
    onResetPassword?: (e: React.SyntheticEvent<HTMLElement>) => void;
    defaultPublicUser?: PublicUser;
    message?: string;
    allowExternalUrls?: boolean;
    skipPostSignUp?: boolean;
    authSource: AuthSource;
    redirect?: string;
    onSuccess?: () => void;
}

const LoginSignUpForm = (props: LoginSignUpFormProps) => {
    const [searchParams] = useSearchParams();
    const {
        flow,
        defaultPublicUser,
        onEmailBlur,
        onResetPassword,
        allowExternalUrls = false,
        skipPostSignUp = false,
        authSource,
        redirect = searchParams.get('redirect') ?? undefined,
        onSuccess,
    } = props;

    const { css } = useStyles({ hasFlow: !!flow });
    const { mobile } = useAppDisplayModeContext();
    const user = useUser();
    const tracker = useTracker();
    const { authRedirect } = useAuth();

    const { enquirySession, setEnquirySession } = useEnquirySession();
    const [passwordVisible, setPasswordVisibility] = useState<boolean>(false);
    const {
        submitButtonText,
        defaultFormValues,
        formValidator,
        displayFormSegments,
    } = useLoginFlow(flow);

    const {
        emailSegment,
        passwordSegment,
        firstNameSegment,
        lastNameSegment,
        rememberMeSegment,
    } = displayFormSegments;

    const defaultValues = defaultFormValues(enquirySession, defaultPublicUser);

    const loginFetcher = useFetcher<LoginAction>();
    const signupFetcher = useFetcher<SignUpAction>();

    const { control, handleSubmit, setValue, getValues } = useForm<FormShape>({
        resolver: zodResolver(formValidator()),
        defaultValues,
        mode: 'onSubmit',
    });

    const loginErrorMessage =
        (loginFetcher.data &&
            assertErrorResponse(loginFetcher.data) &&
            loginFetcher.data.message) ||
        undefined;

    const renderPasswordMessage = () => {
        return {
            dangerouslySetInnerHTML: {
                __html: loginErrorMessage,
            },
            ...(loginErrorMessage?.includes('reset your password') && {
                onClick: onResetPassword,
            }),
        };
    };

    const renderPasswordIcon = () => {
        if (passwordVisible) {
            return (
                <EyeOffLine
                    key="visible"
                    onClick={() => setPasswordVisibility(!passwordVisible)}
                />
            );
        }
        return (
            <EyeLine
                key="invisible"
                onClick={() => setPasswordVisibility(!passwordVisible)}
            />
        );
    };

    useEffect(() => {
        if (assertSuccessResponse(signupFetcher.data)) {
            authRedirect({
                authSource,
                provider: 'EmailPassword',
                authType: 'sign-up',
                skipPostSignUp,
                allowExternalUrls,
                redirect,
            });
            onSuccess?.();
        }
    }, [
        signupFetcher.data,
        user,
        authRedirect,
        skipPostSignUp,
        authSource,
        allowExternalUrls,
        redirect,
        onSuccess,
    ]);

    useEffect(() => {
        if (
            loginFetcher.data &&
            assertSuccessResponse(loginFetcher.data) &&
            user.__typename === 'Me'
        ) {
            authRedirect({
                authSource,
                provider: 'EmailPassword',
                authType: 'sign-in',
                skipPostSignUp:
                    skipPostSignUp || user.PostSignupQuestionnaireSeen,
                allowExternalUrls,
                redirect,
            });
            onSuccess?.();
        }
    }, [
        loginFetcher.data,
        user,
        authRedirect,
        skipPostSignUp,
        authSource,
        allowExternalUrls,
        redirect,
        onSuccess,
    ]);

    const onSubmit: SubmitHandler<FormShape> = (data) => {
        if (
            !flow ||
            loginFetcher.state !== 'idle' ||
            signupFetcher.state !== 'idle'
        ) {
            return;
        }

        if (flow === 'post-enquiry-sign-up' || flow === 'post-enquiry-login') {
            setEnquirySession({ ...enquirySession, email: getValues('email') });
        }

        if (flow === 'login' || flow === 'post-enquiry-login') {
            tracker.log('accountLogin', {
                data: {
                    step: 2,
                    signInMethod: 'Email',
                },
                url: new URL(window.location.href),
            });

            trackAuthEvent({
                tracker,
                event: 'AuthSignInSubmit',
                authSource,
                provider: 'EmailPassword',
            });

            loginFetcher.submit(data, {
                method: 'post',
                action: `/remix-api/user/login`,
            });
        }

        if (flow === 'sign-up' || flow === 'post-enquiry-sign-up') {
            trackAuthEvent({
                tracker,
                event: 'AuthSignUpSubmit',
                authSource,
                provider: 'EmailPassword',
            });

            signupFetcher.submit(data, {
                method: 'post',
                action: `/remix-api/user/sign-up`,
            });
        }
    };

    const handleEmailBlur = (email: string) => {
        if (email) {
            trackAuthEvent({
                tracker,
                event: 'AuthEmailInput',
                authSource,
                provider: 'EmailPassword',
            });
        }

        onEmailBlur?.(email);
    };

    return (
        <loginFetcher.Form
            id="loginSignUpForm"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
        >
            <ExpandableContainer visible={emailSegment ?? false}>
                <Controller<FormShape>
                    control={control}
                    name={'email'}
                    render={({ field, fieldState: { error } }) => (
                        <TextInput
                            state={error?.message ? 'error' : 'default'}
                            className={css(S.EmailInput)}
                            message={error?.message}
                            size={mobile ? 'lg' : 'md'}
                            input={{
                                ...field,
                                placeholder: 'Enter email address',
                                autoComplete: 'email',
                                required: true,
                                autoFocus: !mobile,
                                autoCapitalize: 'off',
                                onBlur: async (e) => {
                                    setValue(
                                        'email',
                                        e.target.value.toLowerCase()
                                    );
                                    field.onBlur?.();
                                    if (
                                        e.relatedTarget?.id ===
                                        'forgot-password-button'
                                    ) {
                                        // Dont fire onBlur Side Effect if another button was clicked in login component.
                                        // Only fire for native onBlue events
                                        return;
                                    }

                                    handleEmailBlur(field.value);
                                },
                                onKeyDown: (e) => {
                                    if (e.key === 'Enter') {
                                        handleEmailBlur(field.value);
                                    }
                                },
                                ...(!mobile && {
                                    label: 'Email address',
                                }),
                            }}
                        />
                    )}
                />
            </ExpandableContainer>

            <ExpandableContainer visible={passwordSegment ?? false}>
                <Controller<FormShape>
                    control={control}
                    name={'password'}
                    render={({ field, fieldState: { error } }) => (
                        <TextInput
                            className={css(S.PasswordInput)}
                            state={
                                loginErrorMessage || error?.message
                                    ? 'error'
                                    : 'default'
                            }
                            message={
                                loginErrorMessage
                                    ? renderPasswordMessage()
                                    : error?.message
                            }
                            size="md"
                            input={{
                                ...field,
                                placeholder: 'Enter password',
                                autoComplete: 'current-password',
                                required: true,
                                input: {
                                    type: passwordVisible ? 'text' : 'password',
                                },
                                ...(!mobile && {
                                    label: 'Password',
                                }),
                            }}
                            suffixIcons={[renderPasswordIcon()]}
                        />
                    )}
                />
            </ExpandableContainer>

            <ExpandableContainer
                visible={(firstNameSegment || lastNameSegment) ?? false}
            >
                <Controller<FormShape>
                    control={control}
                    name={'firstName'}
                    render={({ field, fieldState: { error } }) => (
                        <TextInput
                            state={error?.message ? 'error' : 'default'}
                            message={error?.message}
                            size="md"
                            input={{
                                ...field,
                                placeholder: 'Enter first name',
                                required: true,
                                ...(!mobile && {
                                    label: 'First name',
                                }),
                            }}
                        />
                    )}
                />
                <Controller<FormShape>
                    control={control}
                    name={'lastName'}
                    render={({ field, fieldState: { error } }) => (
                        <TextInput
                            state={error?.message ? 'error' : 'default'}
                            message={error?.message}
                            size="md"
                            input={{
                                ...field,
                                placeholder: 'Enter last name',
                                required: true,
                                ...(!mobile && {
                                    label: 'Last name',
                                }),
                            }}
                        />
                    )}
                />
            </ExpandableContainer>

            {rememberMeSegment && (
                <Controller<FormShape>
                    control={control}
                    name={'rememberMe'}
                    render={({ field }) => (
                        <Checkbox
                            className={css(S.RememberMeInput)}
                            {...field}
                            label={{
                                children: `Remember me`,
                            }}
                            defaultChecked={true}
                            onChange={(_, data) => {
                                const val = !!data?.checked;
                                setValue('rememberMe', val.toString());
                            }}
                            variables={{
                                checkboxIndicatorLabelGap: pxToRem(4),
                                checkboxIndicatorLabelCheckedGap: pxToRem(4),
                                labelLineHeight: pxToRem(18),
                            }}
                        />
                    )}
                />
            )}

            <Button
                design={{ marginTop: pxToRem(44) }}
                fluid
                size={18}
                key={'login-button'}
                type={'submit'}
                color={'dark'}
                loading={
                    loginFetcher.state === 'submitting' ||
                    signupFetcher.state === 'submitting'
                }
                disabled={
                    user.__typename === 'Me' ||
                    loginFetcher.state !== 'idle' ||
                    signupFetcher.state !== 'idle'
                }
                variables={{ iconPadding: 0 }}
            >
                {submitButtonText()}
            </Button>
        </loginFetcher.Form>
    );
};

export default LoginSignUpForm;
