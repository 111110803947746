import React, { useEffect, useRef, useState } from 'react';
import { useStyles } from '@archipro-design/aria';
import * as S from './ExpandableContainer.style';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    children: React.ReactNode;
    visible: boolean;
}
const ExpandableContainer = ({ visible, children }: Props) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const [expanded, setExpanded] = useState<boolean>(visible);
    const [initialHeight, setInitialHeight] = useState<number>(0);

    const { css } = useStyles({
        initialHeight,
    });

    useEffect(() => {
        if (contentRef.current?.clientHeight) {
            setInitialHeight(contentRef.current.clientHeight);
        }
    }, [expanded]);

    useEffect(() => {
        setExpanded(visible);
    }, [visible]);

    if (!visible) {
        return null;
    }

    return (
        <div className={css(S.CollapsibleContainer)}>
            {(visible || expanded) && <div ref={contentRef}>{children}</div>}
        </div>
    );
};

export default ExpandableContainer;
