import { type FetcherWithComponents } from '@remix-run/react';

export const fetcherIsDone = <T>(
    fetcher: FetcherWithComponents<T>
): fetcher is FetcherWithComponents<T> & {
    data: NonNullable<T>;
} => {
    return (
        fetcher.state === 'idle' &&
        fetcher.data !== null &&
        typeof fetcher.data !== 'undefined'
    );
};

export const fetcherIsInit = <T>(fetcher: FetcherWithComponents<T>) => {
    return (
        fetcher.state === 'idle' &&
        (fetcher.data === null || typeof fetcher.data === 'undefined')
    );
};
