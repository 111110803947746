// User can block or disabled cookies/localStorage on IOS and Firefox?
const isStorageAvailable = (
    type: 'localStorage' | 'sessionStorage'
): boolean => {
    let storage;
    try {
        storage = window[type];
        const x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    } catch (e) {
        return false;
    }
};

let localStorage = isStorageAvailable('localStorage')
    ? window.localStorage
    : undefined;
let sessionStorage = isStorageAvailable('sessionStorage')
    ? window.sessionStorage
    : undefined;

export { localStorage, sessionStorage };
