import { useSessionStorageState } from 'ahooks';
import type { PublicUser } from '~/routes/remix-api.user.public-user';
import {
    EMAIL_INPUT,
    FIRST_NAME_INPUT,
    LAST_NAME_INPUT,
    PASSWORD_INPUT,
    REMEMBER_ME_INPUT,
} from '~/modules/validation/util/form';
import { z } from 'zod';
import type { EnquiryFormShape } from '~/modules/enquiry/component/enquiry-validation/enquiryValidation';

export type LoginFlow =
    | 'login'
    | 'sign-up'
    | 'post-enquiry-sign-up'
    | 'post-enquiry-login'
    | 'forgot-password'
    | 'forgot-password-completed'
    | null;

export const loginValidator = z.object({
    email: EMAIL_INPUT,
    password: PASSWORD_INPUT,
    rememberMe: REMEMBER_ME_INPUT,
});

export const signUpValidator = loginValidator.extend({
    firstName: FIRST_NAME_INPUT,
    lastName: LAST_NAME_INPUT,
});

interface LoginFormSegments {
    emailSegment?: boolean;
    passwordSegment?: boolean;
    firstNameSegment?: boolean;
    lastNameSegment?: boolean;
    rememberMeSegment?: boolean;
}

const POST_SIGNUP_NAVIGATION_KEY = 'post-signup-navigation';

export const useLoginFlow = (flow: LoginFlow) => {
    const [navigatePostSignUp, setPostNavigationSignUp] =
        useSessionStorageState<boolean>(POST_SIGNUP_NAVIGATION_KEY);

    const defaultFormValues = (
        enquirySession?: Partial<EnquiryFormShape>,
        publicUser?: PublicUser
    ) => {
        const defaultPostEnquiryValues = {
            email: enquirySession?.email ?? publicUser?.email,
            password: undefined,
            name: enquirySession?.name,
            rememberMe: 'true',
        };
        switch (flow) {
            case 'post-enquiry-sign-up':
            case 'post-enquiry-login':
                const [firstName, ...rest] =
                    defaultPostEnquiryValues.name?.split(' ') ?? '';
                const lastName = rest.join(' ');
                return {
                    email: defaultPostEnquiryValues?.email,
                    password: undefined,
                    firstName: firstName,
                    lastName: lastName,
                    rememberMe: 'true',
                };
            case 'sign-up':
                return {
                    email: publicUser?.email,
                    firstName: publicUser?.firstName,
                    lastName: publicUser?.lastName,
                    rememberMe: 'true',
                };
            default:
                return {
                    email: publicUser?.email,
                    rememberMe: 'true',
                };
        }
    };

    const getTitle = (): string => {
        switch (flow) {
            case 'forgot-password':
                return 'Forgot password?';
            case 'forgot-password-completed':
                return 'Reset password link sent';
            case 'post-enquiry-sign-up':
            case 'sign-up':
                return 'Sign up';
            case 'post-enquiry-login':
            case 'login':
                return 'Log in';
            default:
                return 'Log in or sign up';
        }
    };

    const formValidator = () => {
        switch (flow) {
            case 'post-enquiry-sign-up':
            case 'sign-up':
                return signUpValidator;
            case 'post-enquiry-login':
            case 'login':
            default:
                return loginValidator;
        }
    };

    const displayFormSegments = (): LoginFormSegments => {
        switch (flow) {
            case 'sign-up':
                return {
                    firstNameSegment: true,
                    lastNameSegment: true,
                    emailSegment: true,
                    passwordSegment: true,
                    rememberMeSegment: true,
                };
            case undefined:
            case null:
                return {
                    emailSegment: true,
                    passwordSegment: false,
                };
            default:
                return {
                    emailSegment: true,
                    passwordSegment: true,
                    rememberMeSegment: true,
                };
        }
    };

    const submitButtonText = () => {
        switch (flow) {
            case 'post-enquiry-login':
            case 'login':
                return 'LOGIN';
            default:
                return 'CONTINUE';
        }
    };

    return {
        title: getTitle(),
        defaultFormValues,
        formValidator,
        displayFormSegments: displayFormSegments(),
        submitButtonText,
        navigatePostSignUp,
        setPostNavigationSignUp,
    };
};
