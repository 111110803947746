import type { StyleRule } from '@archipro-design/aria';
import { labelClassName } from '@archipro-design/aria';
import {
    checkboxSlotClassNames,
    indicatorCheckboxClassName,
} from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';
import { textInputSlotClassNames } from '@archipro-design/aria';

export const PasswordInput: StyleRule<{ hasFlow: boolean }> = ({
    theme,
    hasFlow,
}) => ({
    display: hasFlow ? 'flex' : 'none',
    [`& .${textInputSlotClassNames.message}`]: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        [`& a`]: {
            marginLeft: pxToRem(4),
            color: theme.siteVariables.colors.danger[100],
            textDecoration: 'underline',
        },
    },
});

export const RememberMeInput: StyleRule = ({ theme }) => ({
    paddingTop: pxToRem(16),
    paddingBottom: pxToRem(0),
    [`& .${checkboxSlotClassNames.indicatorContainer}`]: {
        width: pxToRem(18),
        height: pxToRem(18),
        [`& .${indicatorCheckboxClassName}`]: {
            width: pxToRem(13.5),
            height: pxToRem(13.5),
        },
    },
    [`& .${labelClassName}`]: {
        color: theme.siteVariables.colors.charcoal[250],
        fontSize: pxToRem(18),
    },
});

export const EmailInput: StyleRule = () => ({
    [`& input`]: {
        textTransform: 'lowercase',
        '&::placeholder': {
            textTransform: 'none',
        },
    },
});
