import type { Tracker } from '@archipro-website/tracker';
import type { CreateEnquirySource } from '~/modules/enquiry';

interface SubmitCreateEnquiryRequestProps {
    source: CreateEnquirySource;
    isAutoSubmit?: boolean;
}

export const trackSubmitCreateEnquiryRequest = (
    tracker: Tracker,
    props: SubmitCreateEnquiryRequestProps
) => {
    const { source, isAutoSubmit = false } = props;
    const subSource = isAutoSubmit ? 'AutoSubmit' : 'EditEnquiryModal';
    tracker.log('SubmitCreateEnquiryRequest', {
        url: new URL(window.location.href),
        data: {
            Source: `${source}-${subSource}`,
        },
    });
};
