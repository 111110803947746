import {
    Button,
    Caption,
    Flex,
    Loader,
    pxToRem,
    useDeviceBrowserContext,
    useStyles,
} from '@archipro-design/aria';

import { useTracker } from '@archipro-website/tracker';
import * as S from './SocialLogin.style';
import { useEffect, useState } from 'react';

import type { SocialLoginOptions, SocialLoginProvider } from './type';
import { useSocialLogins } from './hook/use-social-logins';
import type { AuthSource } from '../../type';
import { trackAuthEvent } from '../../util/track-auth-event';
interface SocialLoginProps {
    loginOptions?: SocialLoginProvider[];
    preOptionText?: string;
    useEmail?: boolean;
    authSource: AuthSource;
    redirect?: string;
    skipPostSignUp: boolean;
}

const SocialLogin = (props: SocialLoginProps) => {
    const {
        loginOptions,
        preOptionText,
        useEmail,
        authSource,
        redirect,
        skipPostSignUp,
    } = props;
    const { css } = useStyles();
    const tracker = useTracker();
    const { isInstagramOrFacebookApp } = useDeviceBrowserContext();

    // ToDo: Move the member/social functions to remix. Move this to useNavigation for loading state
    // Note: /member/social is forwarding specific headers to server and google oauth, need to be careful which ones we override.
    const [spinner, setSpinner] = useState<SocialLoginProvider | undefined>();
    const [socialMessage, setSocialMessage] = useState<string>(
        'Continue with social logins'
    );
    const renderAllSocials = loginOptions?.length === 0;

    const renderSocialButton = (social: SocialLoginOptions) => {
        // If the user has a specfic social method, filter that one, otherwise show all
        if (
            !renderAllSocials &&
            loginOptions &&
            !loginOptions.includes(social.TITLE)
        )
            return null;

        return (
            <Button
                as="a"
                href={`/member/${social.URL}`}
                key={social.KEY}
                data-button={social.TRACKER_ID}
                fluid
                size={18}
                color="grey"
                iconBefore={social.ICON}
                variables={{
                    buttonPadding: pxToRem(16),
                    iconPadding: 0,
                }}
                onClick={() => {
                    setSpinner(social.TITLE);
                    tracker.log('accountLogin', {
                        url: new URL(window.location.href),
                        data: {
                            step: 2,
                            signInMethod: social.TITLE,
                        },
                    });

                    trackAuthEvent({
                        tracker,
                        event: 'AuthSocialButtonClick',
                        authSource,
                        provider: social.TITLE,
                    });
                }}
                {...(spinner === social.TITLE && {
                    className: css(S.DisabledSocialButton),
                })}
                {...(spinner === social.TITLE && {
                    iconAfter: (
                        <Loader
                            size="smallest"
                            variables={{
                                containerHeights: {
                                    smallest: pxToRem(18),
                                },
                                containerWidths: {
                                    smallest: pxToRem(18),
                                },
                            }}
                        />
                    ),
                })}
            >
                {preOptionText
                    ? `${preOptionText} ${social.TITLE}`
                    : social.TITLE}
            </Button>
        );
    };

    const socialLogins = useSocialLogins({
        loginOptions,
        authSource,
        redirect,
        skipPostSignUp,
        isInstagramOrFacebookApp,
    });
    const renderedSocialLogins = socialLogins.map((social) => {
        return renderSocialButton(social);
    });

    useEffect(() => {
        if (socialLogins.length === 1 && !useEmail) {
            setSocialMessage(
                `Looks like you created your account using ${socialLogins[0]?.TITLE}. We’ll use this to log you in.`
            );
            return;
        }

        setSocialMessage(
            socialLogins.length > 0
                ? 'Continue with social logins'
                : 'Please use email to login'
        );
    }, [socialLogins, useEmail]);

    return (
        <Flex column hAlign="center" gap="space-12" design={{ width: '100%' }}>
            <Caption className={css(S.SocialTitle)} variant="05-alt">
                {socialMessage}
            </Caption>
            {renderedSocialLogins}
        </Flex>
    );
};

export default SocialLogin;
