import type { Tracker } from '@archipro-website/tracker';
import type { CreateEnquirySource } from '~/modules/enquiry';

interface CreatedEnquiryProps {
    source: CreateEnquirySource;
    isMemberGenerated?: boolean;
}

export const trackCreatedEnquiry = (
    tracker: Tracker,
    props: CreatedEnquiryProps
) => {
    const { source, isMemberGenerated } = props;
    tracker.log('CreatedEnquiry', {
        url: new URL(window.location.href),
        data: {
            Source: source,
            ExtraData: isMemberGenerated ? 'memberGenerated' : undefined,
        },
    });
};
