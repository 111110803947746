export const SOCIAL_PROVIDERS = ['Facebook', 'Google', 'LinkedIn'] as const;

export type SocialLoginProvider = (typeof SOCIAL_PROVIDERS)[number];

export interface SocialLoginOptions {
    KEY: string;
    TITLE: SocialLoginProvider;
    URL: string;
    TRACKER_ID: string;
    ICON?: JSX.Element;
}
