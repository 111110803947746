import { z } from 'zod';
import {
    EMAIL_REGEX,
    FULLNAME_REGEX,
    NAME_REGEX,
    PHONE_NUMBER_REGEX,
} from './regex';

export const NAME_INPUT = z
    .string({ required_error: 'Name is required' })
    .min(1, { message: 'Name is required' })
    .max(74, { message: 'The text may not be longer than 74 characters' })
    .regex(FULLNAME_REGEX, {
        message: 'Please enter your full name',
    });

export const FIRST_NAME_INPUT = z
    .string({ required_error: 'First name is required' })
    .min(1, { message: 'First name is required' })
    .max(37, { message: 'The text may not be longer than 37 characters' })
    .regex(NAME_REGEX, {
        message: 'First name must only contain letters, spaces, and hyphens',
    });

export const LAST_NAME_INPUT = z
    .string({ required_error: 'Last name is required' })
    .min(1, { message: 'Last name is required' })
    .max(37, { message: 'The text may not be longer than 37 characters' })
    .regex(NAME_REGEX, {
        message: 'Last name must only contain letters, spaces, and hyphens',
    });

export const PHONE_NUMBER_INPUT = z
    .string({ required_error: 'Phone number is required' })
    .min(4, { message: 'Phone number is required' })
    .regex(PHONE_NUMBER_REGEX, 'Phone number is invalid');

export const EMAIL_INPUT = z
    .string({ required_error: 'Email address is required' })
    .min(1, { message: 'Email address is required' })
    .email('Email address is invalid')
    .regex(EMAIL_REGEX, 'Email address is invalid');

export const PASSWORD_INPUT = z
    .string({ required_error: 'Password is required.' })
    .min(6, { message: 'Password must be 6 characters or more' });

export const MESSAGE_INPUT = z
    .string({ required_error: 'Message is required' })
    .min(1, { message: 'Message is required' })
    .regex(/[a-zA-Z]/, { message: 'Please enter a valid message' });

export const PLACE_INPUT = z.object({
    latitude: z.number().optional(),
    longitude: z.number().optional(),
    region: z.string().optional(),
    suburb: z.string().optional(),
    city: z.string().optional(),
    state: z.string().optional(),
    postCode: z.string().optional(),
});

export const REMEMBER_ME_INPUT = z.string();
export const ORGANISATION_INPUT = z.string().optional();

export const ADDRESS_INPUT = z.string();
