import type { StyleRule } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';

export const SocialTitle: StyleRule = ({ theme }) => ({
    color: theme.siteVariables.colors.charcoal[100],
    fontSize: pxToRem(12),
});

export const DisabledSocialButton: StyleRule = () => ({
    pointerEvents: 'none',
});
