// use dvh height if supported
// usage examples:
// getDvhStyles()
// getDvhStyles('minHeight', '60rem', 50)
// getDvhStyles('height', pxToRem(130))
export const getDvhStyles = (
    cssName: 'height' | 'minHeight' | 'maxHeight' = 'height',
    offset = '',
    dvhNumber = 100
) => {
    return {
        [cssName]: offset
            ? `calc(${dvhNumber}vh - ${offset})`
            : `${dvhNumber}vh`,
        '@supports(height: 100dvh)': {
            [cssName]: offset
                ? `calc(${dvhNumber}dvh - ${offset})`
                : `${dvhNumber}dvh`,
        },
    };
};
