import type { ObjectShorthandValue } from '@archipro-design/aria';
import { Caption } from '@archipro-design/aria';
import { Link } from '@remix-run/react';
import { useCountryContext } from '@archipro-website/localisation';
import type { CaptionProps } from '@archipro-design/aria';

export const PolicyLink = (props: ObjectShorthandValue<CaptionProps>) => {
    const { byCountry } = useCountryContext();

    const policyLink = byCountry({
        AU: 'https://email.archipro.com.au/archipro-privacy-and-data-policy-aus',
        NZ: 'https://email.archipro.co.nz/archipro-privacy-and-data-policy-nz',
    });

    const linkProp: ObjectShorthandValue<CaptionProps> =
        props.as === 'a'
            ? { as: 'a', href: policyLink }
            : { as: Link, to: policyLink };

    return (
        <Caption variant="02" {...props} {...linkProp}>
            Privacy Policy
        </Caption>
    );
};
