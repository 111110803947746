export const isCleanRedirect = (
    redirectURL: string,
    allowExternalUrls?: boolean
): boolean => {
    // eslint-disable-next-line no-script-url
    const blacklist = ['javascript:', 'vbscript:'];

    if (!allowExternalUrls) {
        blacklist.push('//');
    }

    redirectURL = redirectURL.toLocaleLowerCase();
    return !blacklist.some((substring) => redirectURL.includes(substring));
};

export const getLoginRedirectUrl = (loginRoute = '/login') => {
    if (typeof window === 'undefined') {
        return loginRoute;
    }
    const redirect = encodeURIComponent(
        window.location.pathname + window.location.search
    );

    const searchParams = new URLSearchParams(window.location.search);
    const skipPostSignUp = searchParams.get('skipPostSignUp') === '1';

    const url = isCleanRedirect(redirect)
        ? `${loginRoute}${
              loginRoute.includes('?') ? '&' : '?'
          }redirect=${redirect}${skipPostSignUp ? '&skipPostSignUp=1' : ''}`
        : loginRoute;

    return url;
};
