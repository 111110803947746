import { useFetcher } from '@remix-run/react';
import { useMemoizedFn } from 'ahooks';
import { useEffect, useState } from 'react';
import type { PublicUser } from '~/routes/remix-api.user.public-user';
import type { AuthSource } from '../type';
import { useTracker } from '@archipro-website/tracker';
import { trackAuthEvent } from '../util/track-auth-event';

export const usePublicUser = (
    authSource: AuthSource,
    defaultUser?: PublicUser
) => {
    const tracker = useTracker();

    const [publicUser, setPublicUser] = useState<PublicUser | undefined>(
        defaultUser
    );

    const userExistsFetcher = useFetcher<PublicUser>();

    useEffect(() => {
        if (
            userExistsFetcher.data &&
            'type' in userExistsFetcher.data &&
            userExistsFetcher.state === 'idle'
        ) {
            setPublicUser(userExistsFetcher.data);
            trackAuthEvent({
                tracker,
                event: 'AuthAccountRecognized',
                provider: 'EmailPassword',
                authSource,
                data: { isNewAccount: userExistsFetcher.data.type === 'new' },
            });
        }
    }, [userExistsFetcher, tracker, authSource]);

    const handleUserExists = useMemoizedFn(async (email: string) => {
        if (!email || publicUser?.email === email) return;
        userExistsFetcher.submit(
            {
                email: email,
            },
            {
                method: 'get',
                action: '/remix-api/user/public-user',
            }
        );
    });

    const publicUserLoginFlow = publicUser
        ? publicUser.type === 'existing'
            ? ('login' as const)
            : ('sign-up' as const)
        : undefined;

    return { publicUser, setPublicUser, handleUserExists, publicUserLoginFlow };
};
