import type { ApolloError } from '@apollo/client';
import type { SafeParseError, typeToFlattenedError } from 'zod';

export interface ErrorResponse {
    error: boolean;
    message: string;
    __ap_error: true;
}

export interface MessageResponse {
    message: string;
    skipPostSignup?: boolean;
}

const ERROR_RESPONSE_BASE = {
    error: true,
    __ap_error: true,
} as const;

/**
 * Checks the serialized response of errors
 * and returns whether it comes from us.
 */
export const isApError = (error: unknown): error is ErrorResponse => {
    if (!error || typeof error !== 'object') return false;
    return '__ap_error' in error;
};

const error = <T = string>(
    message: string | typeToFlattenedError<T>
): string => {
    return JSON.stringify({ ...ERROR_RESPONSE_BASE, message });
};

const isZodError = <T>(
    error: Error | string | SafeParseError<T>
): error is SafeParseError<T> => {
    return (error as SafeParseError<T>)?.error !== undefined;
};

export class UserErrorResponse<T = string> extends Response {
    constructor(inputError: Error | string | SafeParseError<T>) {
        let errorMessage: string | typeToFlattenedError<T> =
            'Something went wrong';

        // TODO IMPROVE https://github.com/colinhacks/zod/blob/master/ERROR_HANDLING.md#a-demonstrative-example
        if (isZodError(inputError)) {
            errorMessage = inputError.error.flatten();
        } else if (typeof inputError === 'string') {
            errorMessage = inputError;
        } else {
            errorMessage = inputError.message ?? errorMessage;
        }

        super(error(errorMessage), {
            status: 400,
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
        });
    }
}

export class UnauthorizedMessageResponse extends Response {
    constructor(messageResponse: MessageResponse) {
        super(error(JSON.stringify({ ...messageResponse })), {
            status: 401,
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
        });
    }
}

export class ServerErrorResponse extends Response {
    constructor(_error?: ApolloError | Error | string | unknown) {
        let errorMessage = 'Server Error';
        if (typeof _error === 'string') {
            errorMessage = _error as string;
        } else {
            errorMessage =
                (_error as ApolloError | Error)?.message ?? errorMessage;
        }

        super(error(errorMessage as string), {
            status: 500,
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
        });
    }
}

export const assertSuccessResponse = <T>(
    response: object
): response is SuccessResponse<T> => {
    return response && 'success' in response;
};

export const assertErrorResponse = (
    response: object
): response is ErrorResponse => {
    return response && 'error' in response;
};

export interface SuccessResponse<T = unknown> {
    success: true;
    data?: T;
}

export class SuccessResponse<T> extends Response {
    constructor(data?: T, init?: ResponseInit | undefined) {
        const mergedHeaders = new Headers(init?.headers);
        mergedHeaders.set('Content-Type', 'application/json; charset=utf-8');
        super(
            JSON.stringify({ success: true, data: data } as SuccessResponse),
            {
                status: 200,
                headers: mergedHeaders,
            }
        );
    }
}
